<template>
   <div class="aboutUs">
    <!-- 关于我们 -->
    <img class="img" src="@/assets/image/aboutUs.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
.aboutUs{
    
    margin: auto;
    margin-top: 80px;
    max-width: 1000px;
    /* height: auto; */

    /* background-image: url(../assets/image/aboutUs.png); */
}
.aboutUs .img{
    width: 100%;
}
</style>